import { mapActions, mapState, mapGetters } from 'vuex'
import InfoSelect from "components/InfoSelect/InfoSelect"
import Modal from "components/Modal/Modal"
import Protocol from "./template/protocol.vue"
import ValinsProtocol from "./template/valinsProtocol.vue"
import ProtocolCom from "./components/protocolCom.vue"
import Comment from "./components/comment.vue"
import Dbp from "./components/dbp.vue"
// import DoorTime from "./components/doorTime.vue"
import TakeLoc from "./components/takeLoc.vue"
import util from "lib/util"
import Bus from './bus.js'
import Mpopup from "components/Mpopup/Mpopup"
import Slider from 'vant/lib/slider';
import 'vant/lib/slider/style';
import Picker from 'vant/lib/picker';
import 'vant/lib/picker/style';
import TopNav from "components/TopNav/TopNav"
import ShowComList from "components/ShowComList/ShowComList"
export default {
  name: "dispatch",
  components: {
    InfoSelect,
    Modal,
    Protocol,
    ValinsProtocol,
    Comment,
    Dbp,
    ProtocolCom,
    DoorTime: ()=> import('./components/doorTime.vue'),
    TakeLoc,
    Mpopup,
    Slider,
    Picker,
    TopNav,
    ShowComList
  },
  data() {
    return {
      noScroll: false,
      mobileReg: /^1\d{10}$/,
      night: false,
      doorTimelist: {},
      isExistMkt: false,
      sendman: {},
      recman: {},
      showAddressEdit: false,
      titleEdit: '地址',
      contactMetas: {
        showAddressEdit: false,
        showAddress: false,
        type: '',
        selectId: '',
        detail: {},
        supportGetPhone: false
      },
      orderData: {
        cargo: '',
        weight: 1,
        cargoInput: '',
        picurl: '',
        cargoImgUrl: '',
        chooseBjIndex: 0,
        valinsFee: '',
        agreeValins: false,
        valinspay: '',
        cargoDesc: [],
        comment: '',
        doortime: '',
      },
      orderDataBackup: {},
      showCargo: false,
      cargoList: [ {name:'日用品', data: ['含液体', '含电池']}, 
        {name:'服饰'}, 
        {name:'文件', data: ['含法律公文']}, 
        {name:'食品', data: ['含液体']},
        {name:'证件'},
        {name:'数码家电', data: ['含电池']},
        {name:'其他', data: ['含液体', '含电池']},
      ],
      isShowPicTips: false,
      isShowDBP: false,
      oldcargoDesc: [],
      isShowNewCom: false,
      chooseComIndex: [],
      availableCom4BrandList: [],
      isShowChooseWeight: false,
      chooseNow: 1, // 0当前定位 1寄件人定位
      queryFirstKuaidComData: {}, // 预派单
      choosePaywayIndex: 0, // 寄付 到付
      isShowBj: false,
      isShowLoading: false,
      isShowSmallLoading: false, // 改价格后的loading
      showComTips: true,
      scrollTopCom: 0,
      isExpressing: false,
      samearea: 1,
      remindArea: true,
      disCoupon: [],
      isShowDmask: false,
      lastMktInfo: {},
      showTakeLoc: false,
      oldWeight: 1,
      orderSource: 'orderindex',
      address2geoData: '',
      gotXzq: {},
      isResetLoc: false,
      preLoc: '',
      showAreaTips: false,
      doorModal: false,
      doordate: [],
      doorTime: [],
      indexArry: [0, 0],
      commentModal: false,
      serviceTimeTips: '',
      serviceTimeTipsShow: false,
      comlistComData: [],
      priceInfo: {},
      bestCount: 0,
      otherCount: 0,
      protocolMeta: {
        agree: false,
        show: false,
        type: 1
      },
      payDetailModal: false,
      couponModal: false,
      isShowRepayTips: false,
      valinsProtocolMeta: {
        show: false
      },
      hasPassed: false,
      showBindMobile: false,
      inputCode: '',
      newFans: 0,
      generateUuidData: '',
      codeWord: '获取验证码',
      removeCount: false,
      autoFit: true,
      isShowLast: false,
      lastMktInfo: {},
      hideLast: '0',
      openBest: '0',
      canShowGpTis: true,
      inviteSign: '',
      kuaidiCom: '',
      usersenttype: '',
      cargoComplete: 0,
      source: 'dispatch',
      num: 0,
      nightTips: '',
      as: '',
      islogin: false,
      columns: [],
      stopTime: false,
      showOpenAlipay: false,//开通支付宝弹窗
      showAlipayBack: false,//支付宝返回显示弹窗
      showALipayFailTips: false,//开通失败弹窗
      alipayData: {
        alipaystatus: 0, // 是否签约
        alipayBtn: true // 是否勾选
      },
      oldId: '',//优惠券选择前id
      couponId: '',//优惠券id
      useCoupon: 'Y',//是否使用优惠券
      offlineComList: [], // 线下运力列表
      chooseOfflineIndex: 0, // 线下运力选择下标
      coname: '',
      addrBook: [],
      currentButtonIndex: 0,
      showComList: [],
      showComCount: 5,
      prescription: {},
      chooseComId: '',
      from: '',
    }
  },
  computed: {
    ...mapState({
      locationInfo: state => state.position || {},
      province: state => state.positionInfo.addressComponent.province || '',
      city: state => state.positionInfo.addressComponent.city || '',
      district: state => state.positionInfo.addressComponent.district || '',
      gotAddr: state => state.positionInfo.formattedAddress || '',
    }),
    newColumns () {
      let list = this.columns
      for (let i=0; i<this.doorTime.length; i++) {
        if (this.doorTime[i].itemName == 2) {
          if (this.isExistMkt) {
            list[1].values[i] = this.doorTime[i].itemValue + (this.doorTime[i].descr || '')
          } else if (!this.isExistMkt && this.queryFirstKuaidComData.kdbest === 'Y') {
            list[1].values[i] = this.doorTime[i].itemValue + '约满'
          }
        }
      }
      return list
    },
    newComName () {
      let list = this.chooseComIndex
      let len = list.length
      if (len === 0) {
        return ''
      } else {
        for (var i = 0; i < len - 1; i++) {
          for (var j = 0; j < len - 1 - i; j++) {
            // 相邻元素两两对比，元素交换，大的元素交换到后面
            if (list[j] > list[j + 1]) {
              var temp = list[j]
              list[j] = list[j + 1]
              list[j + 1] = temp
            }
          }
        }
        const arr = []
        for (let i = 0; i < list.length; i++) {
          arr.push(this.availableCom4BrandList[list[i]].name)
        }
        return this.overText(arr.join('/'), 12)
      }
    },
    chooseComIndexLength () { // 选择快递公司的数量
      return this.chooseComIndex.length
    },
    todaySent () {
      return this.doorTimelist.todaySent || false
    },
    showComNum () {
      var count = 0
      for (var i = 0; i < this.availableCom4BrandList.length; i++) {
        if (this.availableCom4BrandList[i].useable === 'Y') {
          count += 1
        }
      }
      return ((count - 3) > 0) && this.showComTips && !this.isShowLoading && !this.isShowChooseWeight
    },
    chooseCargoIndex() {
      let index
      for (let i = 0; i < this.cargoList.length; i++) {
        if (this.cargoList[i].name === this.orderData.cargo) {
          index = i
        }
      }
      return index || 0
    },
    showValinsFee() {
      return this.isShowDBP ? parseFloat(this.orderData.valinspay) <= 1000 ? '0' : this.orderData.valinsFee : this.orderData.valinsFee
    },
    gpSuccessRate () { // 改派成功率
      let num = 80
      if (this.chooseComIndexLength > 1) {
        return (80 + (this.chooseComIndexLength-1) * 5) > 99 ? 99 : (80 + (this.chooseComIndexLength-1) * 5) // 最高显示99
      } else {
        return 0
      }
    },
    expectPrice () { // 预计费用
      return this.priceInfo.preprice +
            (this.orderData.valinsFee ? this.isShowDBP ? this.float(this.orderData.valinspay) <= 1000 ? 0 : this.orderData.valinsFee : this.orderData.valinsFee : 0) + '元'
    },
    paywayText() {
      let text = ''
      if (this.nowList[0]) {
        const payway = this.nowList[0].payway
        if (payway === 0) { // 优选
          text = '在线支付'
        } else if (payway === 1) { // 线下
          text = this.choosePaywayIndex === 0 ? '寄付' : '到付'
        } else if (payway === 3){ // 线上
          text = '支付宝免密支付'
        }
      }
      return text
    },
    nowList() { // 勾选的公司
      const list = []
      for(let i of this.chooseComIndex) {
        list.push(this.availableCom4BrandList[i])
      }
      return list
    },
  },
  mounted () {
    let time = document.getElementById('time')
    time.addEventListener('touchstart', ()=> {
      this.stopTime = true
    })
    time.addEventListener('touchend', ()=> {
      setTimeout(()=> {
        if(this.stopTime) {
          this.stopTime = false
        }
      }, 800)
    })
  },
  activated () { // onShow
    this.generateUuid()
    if (!this.isEmptyObj(this.sendman)) {
      this.address2geo()
    }
  },
  created () { // onLoad
    if(this.$route?.query?.orderSource) {
      this.orderSource = this.$route?.query?.orderSource
    }
    this.from = this.$route?.query?.from || ''
    // this.sendman = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
    this.getconame()
    // let sendman = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
    // this.orderData.agreeValins = localStorage.getItem('dispatch_agreeValins') || false
    if(localStorage.getItem('dispatch_agreeValins')) {
      var oldT = new Date(+localStorage.getItem('dispatch_agreeValins'))
      var nowT = new Date()
      var num = (nowT - oldT) / (1000 * 60 * 60 * 24)
      if (num > 30) { // 超过30天 去掉
        localStorage.removeItem('dispatch_agreeValins')
      } else {
        this.orderData.agreeValins = true
      }
    }
    // if(sendman.phone && sendman.phone.indexOf('*') !== -1) { // 明文号码，需要前端脱敏
    //   this.sendman = sendman
    // }
    // 不用定位
    // if(!this.locationInfo.lat || !this.locationInfo.lng) {
    //   this.locateFailFlag = false
    //   util.locate({
    //     success: (data, locateInfo) => {
    //       this.$store.commit("setLocate", locateInfo)
    //       const gotXzq = {
    //         pro: this.province || '',
    //         city: this.city || '',
    //         district: this.district || '',
    //       }
    //       this.gotXzq = gotxzq
    //       this.locateFailFlag = true
    //     }, 
    //     fail: () => {
    //       // 定位失败，不返回九宫格
    //       // this.locateFail()
    //     }
    //   }, this)
    // }
    this.login(async () => {
      console.log('登录了')
      this.islogin = true
      this.getAddrBook().then(()=>{
        this.initAddr()
      }).catch(()=>{})
      this.alipaysignstatus()
    },()=>{
      this.initAddr()
    })
    Bus.$on('addMix', (res)=> { // 地图回来 保存新经纬度
      this.sendman.mixLocation = res.location
      this.exclusiveVisit()
      this.isResetLoc =  true
      this.resetLng = res.resetLng
      this.resetLat = res.resetLat
      this.chooseNow = 1
      this.chooseNowLoc(1)
    })
    /* 重新下单 */
    // this.recman = JSON.parse(localStorage.getItem('dispatch_recman')) || {}
    if (localStorage.getItem('dispatch_orderData')) {
      const dispatch_orderData = JSON.parse(localStorage.getItem('dispatch_orderData'))
      this.orderData.weight = dispatch_orderData.weight
      const arr = ['日用品', '服饰', '文件', '食品', '证件']
      if (arr.indexOf(dispatch_orderData.cargo) === -1) {
        this.orderData.cargo = '其他'
        this.orderData.cargoInput = dispatch_orderData.cargo
      } else {
        this.orderData.cargo = dispatch_orderData.cargo
      }
    }
    // localStorage.removeItem('dispatch_recman')
    localStorage.removeItem('dispatch_orderData')
    // if (this.sendman.name && this.recman.name) {    
    //   this.setGoodsData()
    // }
    /* End */
  },
  methods: {
    ...mapActions('location', [
      'getLocation',
      'getCity'
    ]),
    getconame() {
      // 获取当前页面的完整 URL
      let data = this.$store.state.globalData
      this.coname = data?.coname || ''
      if(this.coname) {
        this.orderSource = this.coname
      }
      console.log('coname',this.coname);
    },
    getAddrBook() {
      const url = '/centeraddress/getSentContact'
      const params = {
        data: {
          tag: 3,
          offset: 1,
          limit: 10,
          contactType: 'ALL'
        },
        needAuth: true
      }
      return new Promise((resolve, reject) => {
        this.$http.post(url, params).then(async(res) => {
          const list = res.data || []
          if (list.length) {
            this.addrBook = list
          }
        }).catch(() => {}).finally(() => {
          resolve()
        })
      })
    },
    getCurrAddress() { // 根据ip获取省市
      const url = '/apicenter/openapi.do?method=getCurrAddress'
      const params = {
        handleFail: false
      }
      return new Promise((resolve, reject) => {
        this.$http.post(url, params).then((res) => {
          let city = res.data.city || ''
          const province = res.data.province.replace('省', '')
          if (['北京市', '天津市', '上海市', '重庆市'].indexOf(city) > -1) {
            city = city.replace('市', '')
          }
          if (city && province) {
            // this.sendman.xzqName = `${province},${city}`
            this.$set(this.sendman, 'xzqName', `${province},${city}`)
          }
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    async initAddr() {
      if(this.from === 'homepage') {
        this.sendman = this.$store.state.dispatch.sendman
        this.recman = this.$store.state.dispatch.recman
        if(this.sendman.phone && this.sendman.phone.indexOf('*') !== -1) {
          localStorage.setItem('dispatch_sendman', JSON.stringify(this.sendman))
          await this.address2geo()
        }
        if(this.recman.name) {
          localStorage.setItem('dispatch_recman', JSON.stringify(this.recman))
        }
        if (this.sendman.name && this.recman.name) {    
          this.setGoodsData()
        }
        this.availableCom4BrandOrigin()
        return
      } else if (this.from === 'pricecheck') {
        const pricecheckInfo = JSON.parse(localStorage.getItem('pricecheckInfo') || '') || {}
        this.sendman.xzqName = pricecheckInfo.sendxzq || ''
        this.recman.xzqName = pricecheckInfo.recxzq || ''
        this.availableCom4BrandOrigin()
        return 
      }
      let sendman = {}
      if(this.addrBook.length) {
        const data = this.addrBook[0]
        if (data.addrs[0].def === 1 && data.mobile) { // 用户默认地址
          sendman = this.getData(data)
          localStorage.setItem('dispatch_sendman', JSON.stringify(sendman))
        }
        else {
          sendman = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
        }
      }
      else {
        sendman = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
      }
      // let sendman = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
      if(sendman.phone && sendman.phone.indexOf('*') !== -1) { // 明文号码，需要前端脱敏
        this.sendman = sendman
        await this.address2geo()
      } else {
        await this.getCurrAddress()
      }
      this.recman = JSON.parse(localStorage.getItem('dispatch_recman')) || { xzqName: this.sendman.xzqName || '' }
      if (this.sendman.name && this.recman.name) {    
        this.setGoodsData()
      } else {
        this.availableCom4BrandOrigin()
      }
    },
    getData(data) {
      const addr = data.addrs[0] || {}
      return {
        id: data.id,
        name: data.name,
        mobile: data.mobile || '',
        phone: data.mobile || '',
        // tel: data.tel || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        tag: data.tag,
        province: addr.province,
        city: addr.city || '',
        district: addr.district || '',
        addr: addr.addr,
        address: addr.addr,
        mixLocation: data.mixLocation || '',
        xzqName: `${addr.province || ''},${addr.city || ''},${addr.district || ''}`
      }
    },
    switchTabIndex(index) {
      if(!(this.sendman.name && this.recman.name)) {
        this.$toast('请先选择收件人信息')
        return
      } else if(!this.orderData.cargo) {
        this.$toast('请先填写物品信息')
        return
      }
      this.currentButtonIndex = index
      this.availableCom4BrandNew()
    },
    choosekd(item) {
      if(item.comService === this.chooseComId) {
        return
      }
      if(!(this.sendman.name && this.recman.name)) {
        this.$toast('请先选择收件人信息')
        return
      } else if(!this.orderData.cargo) {
        this.$toast('请先填写物品信息')
        return
      }
      const index = this.availableCom4BrandList.findIndex(i => i.comService === item.comService)
      if(~index) {
        this.chooseComIndex = [index]
        this.chooseComId = item.comService
        this.kdbestcoupon()
        this.showBj() // 保价
        this.queryFirstKuaidCom()
      }else {
        return
      }
    },
    /**
     * @description 获取预估时间
     */
    async getPrescription() {
      let { sendman, recman } = this
      // if (!(sendman && Object.keys(sendman).length)) {
      //   sendman = await this.getDefaultArr()
      // }
      // if (!(recman && Object.keys(recman).length)) {
      //   recman = await this.getDefaultArr()
      // }
      return new Promise((resolve, reject) => {
        this.$http.get('/apicenter/order.do?method=arrAndShipperTime', {
          data: {
            sendxzq: sendman.xzqName || '',
            recxzq: recman.xzqName || '',
            sendaddr: sendman.address || '',
            recaddr: recman.address || ''
          },
          handleFail: false
        }).then(res => {
          this.setPrescription(res.data.comList)
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    setPrescription(data) {
      const map = {}
      data.map(item => {
        map[item.com] = {
          tips: item.tipsDate,
          kdbestTime: item.kdbestTime,
          time: item.thirdTime,
          predictArriveDay: item.predictArriveDay || '',
          arriveTipsDate: item.arriveTipsDate
        }
      })
      this.prescription = map
    },
    setShowComList(list){
      // this.showComList = list.slice(0,this.showComCount)
      list = list.filter(item => item.useable === 'Y')
      const idx = list.findIndex(i => i.comService === this.chooseComId)
      if (idx > this.showComCount - 1) {
        this.showComList = [
          list[idx],
          ...list.slice(0, this.showComCount - 1)
        ]
      } else {
        this.showComList = list.slice(0, this.showComCount)
      }
      this.getPrescription()
    },
    goUrl () {
      this.$router.push({
        name: "cargoInfo"
      })
    },
    locateFail () { // 定位失败
      let lastLocate = util.getStorage("lastLocate")
      if (lastLocate && lastLocate.position) {
        this.setLocateInfo(lastLocate)
      } else {
        this.$toast('没有定位权限，请先定位')
        setTimeout(()=>{
          this.navigator.switch({
            name: "courier"
          })
        }, 1000)
      }
    },
    setLocateInfo (locateInfo) { //格式化地址信息
      this.$store.commit("setLocate", locateInfo)
      const gotXzq = {
        pro: this.province || '',
        city: this.city || '',
        district: this.district || '',
      }
      this.gotXzq = gotXzq
    },
    async selectContact (data, type) { // 选择地址
      if (!data) return
      if (/台湾|香港|澳门/.test(data.xzqName) && type == 'sendman') {
        this.$confirm({
          content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
          type: 'alert',
          confirmText: "我知道了"
        })
        return
      }
      if(data.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      this.isResetLoc = false
      // this.chooseNow = 0
      this.resetLat = ''
      this.resetLng = ''
      this.lastaddress = undefined
      this[type] = data
      if (type === 'sendman') {
        await this.address2geo() // 修改寄件人 去查地址经纬度
        localStorage.setItem('dispatch_sendman', JSON.stringify(data)) 
      } else {
        localStorage.setItem('dispatch_recman', JSON.stringify(data))
      }
      if(this.sendman.name && this.recman.name) {
        this.setGoodsData()
      }
    },
    loginCallback () {
      this.islogin = true
    },
    showSelectContact (type) { // 选择地址
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast()
      this.$router.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, this[type]),
          source: "dispatch",
          tag: type
        }
      })
    },
    showContact(type) {
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "dispatch"
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: this[type].id || -1
        }
      })
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", (addr, type) => {
        this.selectContact(addr, type)
        this.$broadcastChannel.$off("fillContact")
      })
    },
    generateUuid() {
      let seeds = 'abcdefghijklmnopqrstuvwxyz'
      let arr = seeds.split("").concat(seeds.toUpperCase().split("")).concat('0123456789'.split(""))
      let m = arr.length, i
      while (m) {
        i = Math.floor(Math.random() * m--)
        let temp = arr[m]
        arr[m] = arr[i]
        arr[i] = temp
      }
      this.generateUuidData = arr.slice(0, 16).join("")
    },
    autoOpen() { // 下单优化 勾选快递公司
      let chooseComIndex = this.chooseComIndex
      let availableCom4BrandList = this.availableCom4BrandList
      for (let i = 0; i < chooseComIndex.length; i++) {
        if (availableCom4BrandList[chooseComIndex[i]].servicecount > 1) {
          let item = availableCom4BrandList[chooseComIndex[i]]
          let index = chooseComIndex[i]
          this.$set(this.availableCom4BrandList[index], 'openList', true) // 没有就选第一个
          this.expressBrandServiceList(index, item.sign, () => {
            this.$set(this.availableCom4BrandList[index], 'serviceListIndex', 0) // 没有就选第一个
          })
        }
      }
    },
    chooseNowLoc (index) { // 下单优化 选择 0当前定位 1寄件人定位
      if (!this.address2geoData && !this.isResetLoc && index == 1) { // 解析不出经纬度 去地图
        this.goMap()
      } else if ((!this.sendman.longitude || !this.sendman.latitude) && index == 1 && this.address2geoData) { // 地址簿没有经纬度 实际可以解析经纬度
        this.sendman.longitude = this.address2geoData.split(',')[0]
        this.sendman.latitude = this.address2geoData.split(',')[1]
        this.showTakeLoc = false
        this.chooseNow = 1
        this.remindArea = false // 自己手动换位 就不提示了
        this.availableCom4Brand('set') // 重新获取附近快递公司
      } else {
        this.showTakeLoc = false
        this.chooseNow = index
        this.remindArea = false
        this.availableCom4Brand('set') // 重新获取附近快递公司
      }
    },
    goMap () {
      let sendman = this.sendman
      let [resetXzq, resetAddr] = [sendman.xzqName, sendman.address]
      this.showTakeLoc = false
      this.$router.push({
        name: "dispatchSearch",
        query: {
          preLoc: this.preLoc,
          resetXzq: resetXzq,
          resetAddr: resetAddr,
          source: this.source
        }
      })
    },
    showTransition() {
      this.autoFit = false
    },
    hideTransition () {
      this.autoFit = true
    },
    checklocaoff() {
      const url = '/apicenter/order.do?method=checklocaoff'
      const sendman = this.sendman
      const params = {
        data: {
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          sendxzq: sendman.xzqName,
          sendAddr: sendman.address
        },
        handleFail: false
      }
      this.$http.post(url, params).then((res) => {
        this.samearea = res.data.samearea
      })
    },
    overText (str, num = 6) { // 超出字数
      if (str && str.length > num) {
        return str.substring(0, num) + '...'
      } else {
        return str
      }
    },
    isEmptyObj(obj) {
      return JSON.stringify(obj) === '{}'
    },
    clone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    clearInfo () {
      setTimeout(()=> {
        this.removeCount = true
        this.hasPassed = false
        this.recman = {}
        const orderData = {
          cargo: '',
          weight: 1,
          cargoInput: '',
          picurl: '',
          cargoImgUrl: '',
          chooseBjIndex: 0,
          valinsFee: '',
          agreeValins: this.orderData.agreeValins,
          valinspay: '',
          cargoDesc: [],
          comment: '',
          doortime: '',
        }
        this.orderData = orderData
        this.chooseComIndex = []
        this.availableCom4BrandList = []
        this.remindArea = true
        this.disCoupon = []
        this.priceInfo = {}
        this.generateUuid()
        this.queryFirstKuaidComData = {}
        this.payDetailModal = false
        this.choosePaywayIndex = 0
      }, 500)
    },
    getQueryJson (url) {
      url = decodeURIComponent(url)
      let param = {}; // 存储最终JSON结果对象
      url.replace(/([^?&]+)=([^?&]+)/g, function(s, v, k) {
        param[v] = decodeURIComponent(k); //解析字符为中文
        return k + '=' +  v;
      });
      return param;
    },
    exclusiveVisit () {
      return new Promise((resolve, reject)=> {
        let url = '/apicenter/kdmkt.do?method=exclusiveVisit&act=save'
        const params = {
          data: {
            type: 'MKTSENTCONTACT',
            name: this.sendman.name,
            mobile: this.sendman.phone || '',
            tel: this.sendman.fixedPhone || '',
            province: this.sendman.xzqName.split(',')[0],
            city: this.sendman.xzqName.split(',')[1] || '',
            district: this.sendman.xzqName.split(',')[2] || '',
            addr: this.sendman.address,
            longitude: this.sendman.mixLocation.split(',')[0],
            latitude: this.sendman.mixLocation.split(',')[1],
            mixLocation: this.sendman.mixLocation,
            id: this.sendman.id || '',
            platform: 'MWWW'
          },
          needAuth: true
        }
        this.$http.post(url, params).then(()=> {
          this.sendman.longitude = this.sendman.mixLocation.split(',')[0]
          this.sendman.latitude = this.sendman.mixLocation.split(',')[1]
          resolve()
        }).catch(()=> {
          reject()
        })
      })
    },
    handleGp () { // 不再显示智能派单弹窗
      this.canShowGpTis = false
    },
    cancelAll () {
      this.chooseComIndex = []
    },
    init (e) {
      this.getLocation().then((res)=> {
        this.getCity().then((res)=> {
          
        })
      }).catch(()=> {
        this.failLoc()
      })
    },
    costTotalPrice (item) {
      if (item.serviceListIndex === 0 || item.serviceListIndex == 1) {
        const data = item.serviceList[item.serviceListIndex]
        return data && data.priceText ? data.priceText : item.costTotalPrice
      } else {
        return item.costTotalPrice
      }
    },
    /**
     * @description 确定选择线下公司
     */
    chooseOffLineCom() {
      this.showALipayFailTips = false
      this.clearNewInfo()
      this.$nextTick(() => {
        this.availableCom4BrandList = JSON.parse(JSON.stringify(this.offlineComList))
        this.chooseComIndex = [this.chooseOfflineIndex]
        this.isOffline = 'Y'
        this.queryFirstKuaidCom()
      })
    },
    chooseOfflineComIndex(item,index){
      this.chooseOfflineIndex = index
    },
    clearNewInfo() {
      this.chooseComIndex = []
      this.availableCom4BrandList = []
      this.orderData.doortime = ''
      this.priceInfo = {}
    },
    selectPayway() { // 选择支付方式
      const payway = this.nowList[0].payway
      if (payway === 0) { // 优选
        this.$toast({content: '该快递公司仅支持在线支付', time: 1000})
      } else if (payway === 1) { // 线下
        // this.showPaywayModal = true
        this.$toast({content: '该快递公司仅支持线下支付', time: 1000})
      } else if (payway === 3){ // 线上
        this.$toast({content: '该快递公司仅可使用支付宝免密支付下单', time: 1000})
      }
    },
    async checkOpenAlipay() { // 检查开通状态
      await this.alipaysignstatus()
      if (this.alipayData.alipaystatus === '1') { // 已开通
        this.showAlipayBack = false
        this.toSubOrder()
      } else { // 未开通
        this.$toast({content: '未开通支付宝代扣，请重试', time: 1000})
        this.showAlipayBack = false
        setTimeout(()=> {
          this.showOpenAlipay = true
        }, 1000)
      }
    },
    closeAlipayback() {
      this.showAlipayBack = false
    },
    openAlipaySuccess() {
      setTimeout(()=> {
        this.$set(this.alipayData, 'alipaystatus', '1')
        this.$set(this.alipayData, 'alipayBtn', true)
        this.submitOrder()
      }, 220)
    },
    alipaysignstatus (tag) { // 查询支付宝签约状态
      let loading = this.$loading('正在查询授权结果')
      const url = '/apicenter/kdquerytools.do?method=alipaysignstatus'
      const params = {
        handleFail: false
      }
      return new Promise((resolve, reject)=>{
        this.$http.get(url, params).then((res)=> {
          this.alipayData.alipaystatus = res.data.status
          if (res.data.status === '1') {
            loading.hide()
            this.showPayResult = false
          }
          resolve()
        }).catch(err=> {
          loading.hide()
          resolve()
        }).finally(() => {
          loading.hide()
          resolve()
        })
      })
    },
    openAlipayNow() { // 开通支付宝代扣
      const url = '/apicenter/kdquerytools.do?method=queryAlipaysign'
      const params = {
        handleFail: false,
      }
      this.$http.post(url, params).then((res)=> {
        const signStr = res.data || ''
        if (!signStr) {
          this.showOpenAlipay = false
          this.$toast({content: '开通失败', time: 1000})
          return
        }
        const schemeUrl = this.redirectToAlipay(signStr)
        // location.href = schemeUrl
        this.checkAlipayInstallation(schemeUrl,  ()=> {
          console.log("跳转成功");
          this.showOpenAlipay = false
          const handleVisibilityBack = async ()=> {
            if (document.visibilityState === 'visible') {
              // 页面重新获得焦点的逻辑处理
              console.log('页面重新获得焦点')
              await this.alipaysignstatus()
              if(this.alipayData.alipaystatus === '1') {
                this.toSubOrder()
              } else {
                this.showAlipayBack = true
              }
              document.removeEventListener('visibilitychange', handleVisibilityBack)
            }
          }
          document.addEventListener('visibilitychange', handleVisibilityBack)
        }, ()=> {
          console.log("跳转失败");
          // this.clearInfo()
          // let loading = this.$loading('正在查询授权结果')
          this.$toast({content: '支付宝唤起失败', time: 1000})
          this.showOpenAlipay = false
          this.availableCom4BrandOff()
          // setTimeout(()=>{
          //   loading.hide()
          //   this.showALipayFailTips = true
          // },2000)
        })
      })
    },
    redirectToAlipay(signStr, tag) {
      const enUrl = encodeURIComponent('https://openapi.alipay.com/gateway.do?' + signStr)
      const sc = 'alipays://platformapi/startapp?appId=20000067&url=' + enUrl // 开通免密支付 appId固定为20000067   
        // const schemeUrl = 'alipays://platformapi/startapp?appId=20000067&url=' + encodeURIComponent('https://render.alipay.com/p/yuyan/180020010000706007/index.html?signStr=' + encodeURIComponent(signStr));
        // window.location.href = schemeUrl;
      if (tag === 1) {
        return 'https://render.alipay.com/p/s/i/?scheme=' +  encodeURIComponent(schemeUrl)
      }
      return sc
    },
    checkAlipayInstallation(schemeUrl, successCallback, failCallback) {
      let timeout = 3000 // 设置超时时间为3秒
      let timer
  
      function clearTimer() {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
  
      function handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
          clearTimer()
          if (successCallback) successCallback() // 执行成功逻辑
          window.removeEventListener('visibilitychange', handleVisibilityChange) // 移除事件监听器
        }
      }
      // function checkWithIframe() { //ios safari浏览器唤起不了支付宝
      //   let iframe = document.createElement('iframe')
      //   iframe.style.display = 'none'
      //   iframe.src = schemeUrl
      //   document.body.appendChild(iframe)

      //   timer = setTimeout(function() {
      //     document.body.removeChild(iframe) // 移除iframe
      //     if(failCallback) failCallback() // 执行fail逻辑
      //     window.removeEventListener('visibilitychange', handleVisibilityChange); // 移除事件监听器
      //   }, timeout)

      //   window.addEventListener('visibilitychange', handleVisibilityChange) //页面隐藏即跳转成功

      // }
      const checkWithLocation = ()=>{
        this.$toast("跳转中")
        window.top.location.href = schemeUrl
        timer = setTimeout(function() {
          // document.body.removeChild(iframe) // 移除iframe
          if(failCallback) failCallback() // 执行fail逻辑
          window.removeEventListener('visibilitychange', handleVisibilityChange); // 移除事件监听器
        }, timeout)

        window.addEventListener('visibilitychange', handleVisibilityChange) //页面隐藏即跳转成功
      }
      try {
        // checkWithIframe()
        checkWithLocation()
      } catch (error) {
        console.log(error)
      }
    },
    couponModalClose(tag) {
      this.couponModal = false
      if(tag === 'N') {
        this.useCoupon = this.couponId ? 'Y' : 'N'
        this.availableCom4BrandNew('checkCoupon')
      }
    },
    submit (e) { // 下单
      if (this.isEmptyObj(this.sendman) || this.isEmptyObj(this.recman)) {
        this.$toast({content: '请填写收寄件人地址', time: 1000})
      } else if (!this.sendman.phone) {
        this.$toast({content: '抱歉，暂不支持座机为寄件人联系方式，请填写正确的手机号码', time: 1000})
      } else if (!this.orderData.cargo) {
        this.$toast( {content: '请选择物品信息', time: 1000})
      } else if (!this.newComName) {
        this.$toast( {content: '请选择快递公司', time: 1000})
      } else if (!this.orderData.doortime) {
        this.$toast( {content: '请选择期望上门时间', time: 1000})
      } else if (!this.protocolMeta.agree) {
        this.$toast({content: '请阅读并同意《快递100寄件服务与隐私协议》', time: 1000})
        this.showProtocol(1)
      } else {
        this.submitOrder()
      }
    },
    submitOrder () {
      let useAlipay = this.queryFirstKuaidComData.kdbest === 'N' && this.alipayData.alipayBtn && ['0', '3'].includes(this.queryFirstKuaidComData.payway + '')
      // 没有签约芝麻先亨，且需要使用芝麻先享或者选择的运力只能用芝麻先亨的，则提示开通芝麻先亨
      if (this.alipayData.alipaystatus != 1 && (useAlipay || this.queryFirstKuaidComData.payway == '3') && !(this.queryFirstKuaidComData.kdbest === 'Y')) {
        // this.openAlipayNow()
        this.showOpenAlipay = true
        return
      } else {
        this.toSubOrder()
      }
    },
    toSubOrder () {
      let loading = this.$loading('正在下单')
      let sendman = this.sendman, recman = this.recman
      let url = '/apicenter/order.do?method=submitOrder'
      let payway = this.queryFirstKuaidComData.kdbest === 'N' && this.alipayData.alipayBtn && ['0', '3'].includes(this.queryFirstKuaidComData.payway + '') ? 'ZHIFUBAOCONTRACT' : ''
      const params = {
        data: {
          platform: 'm',
          orderSource: this.orderSource,
          //收件人寄件人传参
          sendxzq: sendman.xzqName,
          sendAddr: sendman.address,
          sendName: sendman.name,
          sendMobile: sendman.phone || '',
          sendTel: sendman.fixedPhone || '',
          saddrid: sendman.id || '',
          recxzq: recman.xzqName,
          recAddr: recman.address,
          recName: recman.name,
          recMobile: recman.phone || '',
          recTel: recman.fixedPhone || '',
          raddrid: recman.id || '',
          //经纬度
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          //订单信息
          cargo: this.orderData.cargo === '其他' ? this.orderData.cargoInput : this.orderData.cargo, 
          weight: this.orderData.weight || 1,
          comment: this.orderData.comment,
          //微信支付分
          payway: payway,
          stampid: '',
          // 下单优化
          cargodesc: this.orderData.cargoDesc.toString(),
          gotaddr: this.chooseNow == 0 ? this.gotAddr : this.sendman.address,
          picurl: '',
          doortime: this.orderData.doortime,
          valinspay: this.orderData.valinspay || '',
          payment: this.choosePaywayIndex == '0' ? 'SHIPPER' : 'CONSIGNEE',
          couponid: this.priceInfo.couponId || 0,
          apiversion: 18,
          comlist: this.myComlist(),
          inviteSign: '',
          uuid: this.generateUuidData
        },
        handleFail: false
      }
      this.$http.post(url, params).then((res)=> {
        if (res.data[0].dispatchid) {
          this.$router.push({
            name: "dispatchDetailIndex",
            query: {
              expid: res.data[0].expId,
              dispatchid: res.data[0].dispatchid
            }
          })
        } else {
          this.$router.push({
            name: "dispatchOfficial",
            query: {
              expid: res.data[0].expId,
              sign: res.data[0].sign
            }
          })
        }
        this.clearInfo()
        localStorage.removeItem('dispatch_recman')
      }).catch((res)=> {
        setTimeout(()=> {
          this.$toast(res.message || '系统繁忙， 请稍后再试')
        }, 250)
      }).finally(()=> {
        loading.hide()
      })
    },
    selectCoupon (item, index) {
      // let couponId = item.id 
      // let couponPrice = item.top_limit
      // let idx = index
      // if (this.queryFirstKuaidComData.kdbest === 'Y' && item.orderType == 13) {
      //   return this.$toast('该券仅限圆通（没有优选标签）可用，若要使用该券，请保证只选择圆通一家快递公司')
      // }
      // if (this.queryFirstKuaidComData.kdbest === 'N' && item.kdbest == 'Y') {
      //   return this.$toast('该券仅限优选可用，若要使用该券，请选择有优选标签的快递公司')
      // }
      // if (this.priceInfo.couponId == couponId) {
      //   couponId = 0
      // }
      // let priceInfo = this.priceInfo
      // let price = +priceInfo.preprice
      // let p = couponId == 0 ? +priceInfo.totalprice : Number(priceInfo.totalprice * 10 - couponPrice * 10) / 10
      
      // this.$set(this.priceInfo, 'couponId', couponId)
      // this.$set(this.priceInfo, 'idx', idx)
      // this.$set(this.priceInfo, 'preprice', p)
      // this.$set(this.priceInfo, 'couponPrice', couponPrice)
      if (item.useable === 'N') {
        return this.$toast(item.unablemsg || '该券不符合使用条件')
      }
      if (this.couponId == item.id) {
        this.couponItem = item
        this.couponId = ''
        return
      }
      this.couponItem = item
      this.couponId = item.id
    },
    showRepayTips () {
      this.isShowRepayTips = true
    },
    choosePayway (index) { // 下单优化 选择支付方式
      this.choosePaywayIndex = index
    },
    getMoreOpt () {
      if (this.isEmptyObj(this.priceInfo)) {
        return this.$toast('请先选择快递公司')
      }
      this.payDetailModal = !this.payDetailModal
    },
    overWeight (a) {
      return ['jd', 'shunfeng'].indexOf(a) > -1 ? false : true
    },
    totalPrice (a) {
      return ['jd', 'shunfeng'].indexOf(a) > -1 || false
    },
    float (n) {
      return parseFloat(n).toFixed(2)
    },
    confirmProtocol () { // 点击同意寄件服务协议
      this.toggleProtocol(true)
      this.protocolMeta.show = false
      if (this.protocolMeta.type == 1) {
        this.submit()
      }
      if(this.protocolMeta.agree) {
        var d = new Date();
        localStorage.setItem('protocolMeta.agree', d.getTime());
      }
    },
    toggleProtocol (v) {
      this.protocolMeta.agree = typeof v == 'boolean' ? v : !this.protocolMeta.agree
    },
    confirmValinsProtocol () {
      this.valinsProtocolMeta.show = false
      this.orderData.agreeValins = true
      var d = new Date();
      localStorage.setItem('dispatch_agreeValins', d.getTime())
    },
    showProtocol (e) {
      this.protocolMeta.show = true
      this.protocolMeta.type = e
    },
    showDoorModal () {
      if (this.isEmptyObj(this.sendman) || this.isEmptyObj(this.recman)) {
        this.$toast('请先填写收寄件人信息')
      } else if (!this.orderData.cargo) {
        return this.$toast('请选择物品信息')
      } else if (!this.newComName) {
        return this.$toast('请选择快递公司')
      } else {
        this.doorModal = true
        if (!this.orderData.doortime) {
          this.getDoorTimeList()
        }
      }
    },
    myComlist () { // 下单优化 整理comlist
      let comlist = []
      for (let i = 0; i < this.chooseComIndexLength; i++) {
        let f = this.chooseComIndex
        const d = {
          com: this.availableCom4BrandList[f[i]].kuaidiCom,
          kdbest: this.availableCom4BrandList[f[i]].kdbest,
          dispatchid: this.availableCom4BrandList[f[i]].dispatchid || "",
          sign: this.availableCom4BrandList[f[i]].sign || "",
          mktid: this.availableCom4BrandList[f[i]].mktid || ''
        }
        if (this.availableCom4BrandList[f[i]].addCom) { // 假快递公司
          d.addCom = this.availableCom4BrandList[f[i]].addCom
        }
        let servicetype
        let a = this.availableCom4BrandList[f[i]]
        if (a.servicecount > 1 && a.serviceList) { // 有多种type
          servicetype = a.serviceList[a.serviceListIndex].serviceTypeName
        } else {
          servicetype = a.servicetype || ''
        }
        d.servicetype = servicetype
        comlist[i] = d
      }
      return JSON.stringify(comlist)
    },
    getTimeArr (arr) {
      let list = []
      for(let i of arr) {
        list.push(i.itemValue)
      }
      return list
    },
    getDoorTimeList (cb) { // 时间
      let loading = this.$loading('正在加载')
      this.resetInfo()
      let sendman = this.sendman
      let recman = this.recman
      let url = '/apicenter/kdmkt.do?method=queryDoorTimeList'
      const params = {
        data: {
          platform: 'm',
          cargo: this.orderData.cargo,
          sendxzq: sendman.xzqName,
          sendAddr: sendman.address,
          recxzq: recman.xzqName,
          recAddr: recman.address,
          cargodesc: this.orderData.cargoDesc ? this.orderData.cargoDesc.toString() : '',
          comlist: this.myComlist(),
          apiversion: 18
        }
      }
      this.$http.post(url, params).then((r)=> {
        loading.hide()
        let doordate = []
        let doorTime = this.doorTime || []
        if (r.data.today && r.data.today != '') {
          doordate = ['今天', '明天', '后天']
        } else {
          doordate = ['明天', '后天']
        }
        if (r.data.today && r.data.today != '') {
          doorTime = r.data.today
        } else {
          doorTime = r.data.tomorrow
        }
        let night = this.night || false
        if (doorTime.length == 1 && doorTime[0].descr) {
          night = true
        }
        this.isExistMkt = r.data.isExistMkt
        this.night = night
        this.doorTimelist = r.data
        this.doordate = doordate
        this.doorTime = doorTime
        this.nightTips = r.data.tips
        const columns = [
          {
            values: doordate,
            defaultIndex: 0
          },
          {
            values: this.getTimeArr(this.doorTime),
            defaultIndex: 0,
            className: 'myTime'
          },
        ]
        this.$set(this, 'columns', columns)
        typeof cb == "function" && cb()
      }).catch(()=> {
        loading.hide()
      })
    },
    confirmDoor () { // 确定时间
      if(this.stopTime) return
      let ddate = this.doordate[this.indexArry[0]]
      let dtime = this.doorTime[this.indexArry[1]].itemValue
      this.orderData.doortime = ddate + dtime
      this.doorModal = false
      if (!this.isEmptyObj(this.priceInfo)) { // 夜间取件时间 nightFee是字符串
        if ((dtime == '19:00-21:00' && this.priceInfo.nightFee == 0) || (this.priceInfo.nightFee != 0 && dtime != '19:00-21:00')) {
          this.getPrice() //选择夜间取件 或者取消夜间取件，价格变化
        }
      }
    },
    bindDoorChange (e) {
      let p = false
      //当前选择index
      let indexValue = this.$refs.myTime.getIndexes()
      //当前选择的日期
      let ddate = this.doordate[indexValue[0]]
      //重置时间
      let doorTime = this.doorTime
      if (ddate == '明天' || ddate == '后天') {
        doorTime = this.doorTimelist.tomorrow || []
      } else {
        doorTime = this.doorTimelist.today || []
      }
      let night = false
      if (!this.isExistMkt && doorTime.length > indexValue[1] && doorTime[indexValue[1]].descr) {
        indexValue[1] = indexValue[1] - 1
        p = true
      } else if (this.isExistMkt && doorTime.length > indexValue[1] && doorTime[indexValue[1]].descr) {
        night = true
      }
      if (!p) {
        this.doorTime = doorTime
        this.indexArry = indexValue
        this.night = night
      } else { // 不这样做 无法渲染
        let a = doorTime
        this.doorTime = []
        this.$nextTick(()=> {
          this.doorTime = a
          this.indexArry = indexValue
          this.night = night
        })
      }
      this.columns[1].values = this.getTimeArr(this.doorTime)
      e.setIndexes(this.indexArry)
      this.$nextTick(()=> {
        const arr = this.indexArry
        arr[1] = e.getColumnIndex(1)
        e.setIndexes(arr)
      })
    },
    resetInfo() { // 清除时间
      this.isExistMkt = false
      this.night = false
      this.doorTimelist = {}
      this.doordate = []
      this.doorTime = []
      this.choosePaywayIndex = 0
    },
    scroll () { // 滚动
      if(this.noScroll) return
      let arr = this.availableCom4BrandList
      let count = 0
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].useable === 'Y') {
          count += 1
        }
      }
      if ((count - 3) > 0) {
        this.showComTips = false
      }
    },
    handleArea () {
      this.showAreaTips = false
      this.remindArea = false
      this.chooseComDone()
    },
    confirmCom () { // 下单优化 关闭选择公司
      // this.chooseComIndexLength === 0 看有没有勾选
      if(this.isShowChooseWeight) {
        this.hideChooseWeight()
        return
      }
      if (this.chooseComIndexLength === 0 && this.availableCom4BrandList.length > 0) { // 没有选公司
        return this.$toast('请选择快递公司')
      }
      if (!this.isResetLoc && !this.address2geoData && this.remindArea && this.chooseComIndexLength === 0 && !this.sendman.mixLocation) { // 查不出经纬度
        this.showAreaTips = true
        return
      }
      if (!this.samearea && this.remindArea && this.chooseComIndexLength > 0 && !this.sendman.mixLocation) { // 不同片区
        this.showAreaTips = true
        return
      }
      // if(!this.coname){
      //   this.isShowNewCom = true
      // }
      this.setShowComList(this.availableCom4BrandList)
      this.chooseComDone()
    },
    hideChooseWeight () { // 隐藏重量
      this.isShowChooseWeight = false
      if (this.oldWeight != this.orderData.weight) {
        this.availableCom4BrandNew()
      }
    },
    chooseComDone () { // 下单优化 选择快递公司 点击完成
      if (this.isExpressing) {
        return
      }
      this.isShowNewCom = false
      this.kdbestcoupon()
      this.showBj() // 保价
      this.queryFirstKuaidCom()
    },
    getDispatchId () { // 下单优化 获取dispatchid
      for (let i = 0; i < this.availableCom4BrandList.length; i++) {
        if (this.availableCom4BrandList[i].dispatchid && this.chooseComIndex.indexOf(i) > -1) {
          return this.availableCom4BrandList[i].dispatchid
        } else if (i == this.availableCom4BrandList.length - 1) {
          return ''
        }
      }
    },
    kdbestcoupon () {
      let url = '/apicenter/card.do?method=kdbestcoupon'
      const params = {
        data: {
          dispatchid: this.getDispatchId(),
          addOfficial: 'Y'
        }
      }
      if (!this.isEmptyObj(this.sendman) && !this.isEmptyObj(this.recman)) {
        let sendman = this.sendman
        params.data.sendxzq = sendman.xzqName.split(',').join()
      }
      this.$http.post(url, params).then((res)=> {
        this.disCoupon = res.data || []
        let a = 0
        let b = 0
        for (let i of this.disCoupon) {
          if (i.kdbest == 'Y') {
            a += 1
          } else {
            b += 1
          }
        }
        this.bestCount = a
        this.otherCount = b
      })
    },
    queryFirstKuaidCom () { // 下单优化 预派单
      let sendman = this.sendman
      let recman = this.recman
      let url = '/apicenter/order.do?method=queryFirstKuaidCom'
      const params = {
        data: {
          recxzq: recman.xzqName.split(',').join(),
          recAddr: recman.address || '',
          raddrid: recman.id || '',
          saddrid: sendman.id || '',
          weight: this.orderData.weight,
          cargo: this.orderData.cargo,
          sendxzq: sendman.xzqName.split(',').join(),
          sendAddr: sendman.address,
          cargodesc: this.orderData.cargoDesc.toString(),
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          comlist: this.myComlist()
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.setServiceTime(res.data[0].serviceTime)
        this.queryFirstKuaidComData = res.data[0]
        if (this.queryFirstKuaidComData.payment !== 'ALL' && this.choosePaywayIndex == 1) {
          this.choosePaywayIndex = 0
        }
        this.clearDoorTime()
        this.getDoorTimeList(()=> {
          this.confirmDoor()
          this.getPrice()
        })
        this.scrollTopCom = 0
        setTimeout(() => {
          this.showComTips = true
        }, 400)
        if (this.queryFirstKuaidComData.kdbest === 'N') {
          this.orderData.valinspay = ''
          this.orderData.chooseBjIndex = 0
          this.orderData.valinsFee = ''
        }
      }).finally(()=> {
        
      })
    },
    clearDoorTime() {
      this.orderData.doortime =  ''
      this.indexArry = [0, 0]
      this.night = false
    },
    setServiceTime(time) {
      let date = new Date()
      let times = time.split("-")
      let timestr = ('0' +date.getHours()).slice(-2) + ":" + ('0' +date.getMinutes()).slice(-2)
      let serviceStr = ""
      if (timestr < times[0]) {
        serviceStr = `现在下单预计${times[0]}之后取件`
      } else if (timestr > times[1]) {
        serviceStr = `现在下单预计明天${times[0]}之后取件`
      }
      if (serviceStr){
        this.serviceTimeTips = serviceStr
        this.serviceTimeTipsShow = true
        setTimeout(() => {
          this.serviceTimeTipsShow = false
        }, 2500)
      }
    },
    sliderChanging (e) { // 重量滑块
      this.$nextTick(()=> {
        this.orderData.weight = e
      })
    },
    showChooseWeight () {
      this.oldWeight = this.orderData.weight
      this.isShowChooseWeight = !this.isShowChooseWeight
    },
    changeServerIndex (index, index1) { // 下单优化 点击切换服务
      let chooseComIndex = this.chooseComIndex
      if (chooseComIndex.indexOf(index) == -1) {
        chooseComIndex.push(index)
      }
      this.$set(this.availableCom4BrandList[index], 'serviceListIndex', Number(index1))
      this.chooseComIndex = chooseComIndex
    },
    showServiceList (index, sign) { // 下单优化 服务列表 开闭
      this.expressBrandServiceList(index, sign)
      this.$set(this.availableCom4BrandList[index], 'openList', !this.availableCom4BrandList[index].openList)
    },
    chooseCom (item, index) { // 下单优化 勾选快递公司
      if (item.useable !== 'Y') {
        return
      }
      let chooseComIndex = this.chooseComIndex
      let isClose
      if (chooseComIndex.indexOf(index) === -1) { // 未勾选
        // chooseComIndex.push(index)
        chooseComIndex = [index]
      } else {
        chooseComIndex.splice(chooseComIndex.indexOf(index), 1)
        isClose = true
      }
      this.chooseComIndex = chooseComIndex
      this.chooseComId = this.availableCom4BrandList[chooseComIndex].comService
      if (item.servicecount && item.servicecount > 1) {
        this.$set(this.availableCom4BrandList[index], 'openList', isClose ? false : true)
        this.expressBrandServiceList(index, item.sign, ()=> {
          let s = this.availableCom4BrandList[index].serviceListIndex
          if (s !== null && s > -1) { // serviceListIndex
            if (isClose) { // 取消勾选时 去掉选中的服务列表
              this.$set(this.availableCom4BrandList[index], 'serviceListIndex', null)
            }
          } else {
            this.$set(this.availableCom4BrandList[index], 'serviceListIndex', 0) // 没有就选第一个
          }
        })
      }
    },
    expressBrandServiceList (index, sign, cb) { // 下单优化 服务列表 调接口
      if (!sign) return
      let url = '/apicenter/kdmkt.do?method=expressBrandServiceList'
      const params = {
        data: {
          sign: sign,
          sendxzq: this.sendman.xzqName.split(',').join(),
          sendAddr: this.sendman.address,
          recxzq: this.recman.xzqName.split(',').join(),
          recAddr: this.recman.address,
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          weight: this.orderData.weight,
          cargo: this.orderData.cargo
        },
        handleFail: false,
        ignoreException: true
      }
      this.$http.post(url, params).then((res) => {
        this.$set(this.availableCom4BrandList[index], 'serviceList', res.data)
        typeof cb == "function" && cb()
      }).catch((err)=>{
        console.log(err)
      })
    },
    changeLoc () { // 更换位置
      this.showTakeLoc = true
    },
    /* 过滤 Fun*/
    checkCom (index, arr) {
      return arr.indexOf(index) > -1
    },
    comNum (arr) {
      var count = 0
      for (var i = 0; i < arr.length; i++ ){
        if (arr[i].useable === 'Y') {
          count += 1
        }
      }
      return count - 4
    },
    indexOf (a, b) {
      return a ? a.indexOf(b) > -1 : false
    },
    /* End */
    showNewCom () {
      if (JSON.stringify(this.recman) !== '{}' && JSON.stringify(this.sendman) !== '{}' && this.orderData.cargo) { // 防止从订单分享过来的人不填手机号
        this.isShowNewCom = true
        this.noScroll = true
        this.checklocaoff()
        setTimeout(()=> {
          if(!this.isShowLoading && this.availableCom4BrandList.length === 0) { // 没有loading也没有公司 那就打开时调一次接口（分享订单需求）
            this.availableCom4Brand('set')
          }
          this.noScroll = false
        }, 200)
        setTimeout(()=> {
          this.$refs.sendchoose.scrollTop = 0
        }, 100)
      } else {
        this.$toast(!this.orderData.cargo ? '请先选择物品信息' : '请先填写收寄件人地址')
      }
    },
    toggleValinsAgree () { // 保价协议 打勾
      if(this.orderData.agreeValins) {
        this.orderData.agreeValins = false
        localStorage.removeItem('dispatch_agreeValins')
      } else {
        this.valinsProtocolMeta.show = true
      }
    },
    chooseBj (index) { // 选择是否保价
      this.orderData.chooseBjIndex = index
    },
    selectCargoDesc (item, index) { // 物品类型 带液体等 打勾
      if (this.orderData.cargoDesc.indexOf(item) > -1) {
        this.orderData.cargoDesc.splice(this.orderData.cargoDesc.indexOf(item), 1)
      } else {
        this.orderData.cargoDesc.push(item)
      }
    },
    showPicTips () { // 物品拍照弹窗提示
      this.isShowPicTips = !this.isShowPicTips
    },
    chooseCargo (item) { // 点击物品类型
      this.orderData.cargoDesc = []
      this.orderData.cargo = item.name
    },
    selectCargo () { // 打开物品弹窗
      if (this.isEmptyObj(this.sendman) || this.isEmptyObj(this.recman)) {
        return this.$toast('请先填写收寄件人地址')
      }
      this.showCargo = true
      this.orderDataBackup = this.clone(this.orderData)
      this.oldcargoDesc = this.clone(this.orderData.cargoDesc)
    },
    cancelCargo () { // 取消物品弹窗
      this.orderData = this.orderDataBackup
    },
    confirmCargo () { // 物品信息 完成
      if (this.orderData.cargo == '其他' && !this.orderData.cargoInput) {
        this.$toast('请输入物品名称')
        return
      }
      if (isNaN(this.orderData.weight) || (this.orderData.weight < 1 || this.orderData.weight > 20)){
        this.$toast('物品重量：请输入1-20之间的整数')
        return
      } 
      if (this.isShowBj && this.orderData.chooseBjIndex == 1 ) { // 选了保价
        let valinspay = this.orderData.valinspay
        if (!valinspay || Number(valinspay)%100 != 0) {
          this.$toast('请输入100的整倍数')
          return
        } else if (Number(valinspay) > 10000) {
          this.$toast('仅支持10000元以下的物品保价')
          return
        } else if (!this.orderData.agreeValins) {
          this.$toast('请阅读并同意《快递100平台保价增值服务协议》')
          return
        }
      }
      if (this.orderData.chooseBjIndex == 0) {
        this.orderData.valinspay = ''
        this.orderData.valinsFee = 0
      }
      this.orderData.cargo = this.cargoList[this.chooseCargoIndex].name
      this.showCargo = false
      if (this.orderData.cargoDesc.length > 0 || this.orderData.cargo === '证件') { // 有特殊物品
        if (!this.includes(this.oldcargoDesc, this.orderData.cargoDesc) || this.orderData.cargo === '证件') {
          this.$toast('包含特殊物品，请重新选择快递公司')
          this.isShowNewCom = true
          this.checklocaoff()
          this.availableCom4Brand('set')
        }
      } else { // 无特殊物品
        if (this.availableCom4BrandList.length > 0) {
          this.availableCom4BrandNew()
        } else {
          // if(this.coname) {
          //   this.availableCom4Brand()
          // }
          // else {
          //   this.availableCom4Brand('popup')
          // }
          this.availableCom4Brand()
        }
      }
      this.cargoComplete += 1
    },
    includes (arr1, arr2) {
      return arr2.every(val => arr1.includes(val));
    },
    adjustWeight (tag) { // 调整重量
      if (tag === 'add') {
        this.orderData.weight = this.orderData.weight > 19 ? this.orderData.weight : this.orderData.weight + 1
      } else {
        this.orderData.weight = this.orderData.weight < 2 ? this.orderData.weight : this.orderData.weight - 1
      }
    },
    changeWeightInput (e) { // 输入框 调整重量
      if (Number(e.detail.value) < 1 || Number(e.detail.value) > 20 || isNaN(e.detail.value)){
        if (e.detail.value) {
          this.$toast('请输入1-20之间的整数')
          this.$nextTick(()=> {
            this.orderData.weight = Number(e.detail.value.substring(0, e.detail.value.length -1))
          })
        }
      } else {
        this.$nextTick(()=> {
          this.orderData.weight = Number(e.detail.value)
        })
      }
    },
    async address2geo () { // 下单优化 检测经纬度
      let url = '/apicenter/xcx.do?method=address2geo'
      const params = {
        data: {
          address: this.sendman.xzqName.split(',').join() + this.sendman.address
        }
      }
      await this.$http.post(url, params).then((res)=> {
        this.address2geoData = res.data
        if(this.address2geoData) { // 如果切换寄件地址了，将寄件地址的经纬度更新
          this.sendman.longitude = this.address2geoData.split(',')[0]
          this.sendman.latitude = this.address2geoData.split(',')[1]
          this.locationInfo.lat = this.sendman.latitude
          this.locationInfo.lng = this.sendman.longitude
          this.$store.commit("setLocate", this.locationInfo)
          
        }
        if (!this.address2geoData) { // 检测不到经纬度
          this.searchAddress()
        }
      })
    },
    searchAddress () {
      // let url = 'https://restapi.amap.com/v3/place/text'
      const url = '/third/api/gaodeform'
      const params = {
        data: {
          // key: '77d5e24ecae26447c607d3a152b3ddf5',
          keywords: this.sendman.address,
          types: '',
          city: this.sendman.xzqName.split(',')[1] || '',
          children: 1,
          offset: 10,
          page: 1,
          extensions: 'all',
          path: 'v3/place/text',
          pd: 'SELF',
          client: 'M',
          ref: '/express/dispatch/index',
          jscode: '6be829187e17a2d6466b65225ea4c131',
          s: 'rsv3'
        },
        handleFail: false,
        emptyUser: true
      }
      this.$http.post(url, params).then((res) => {
        this.preLoc = JSON.stringify(res?.data?.pois) !== '[]' ? res.data.pois[0].location : ''
      })
    },
    setGoodsData () { // 填写完收寄地址后自动填充物品信息
      let orderData = this.orderData
      this.orderData.cargoDesc = orderData.cargoDesc || [],
      this.orderData.cargo = orderData.cargo || '日用品',
      this.orderData.weight = orderData.weight || 1,
      this.orderData.cargoImgUrl = orderData.cargoImgUrl || '',
      this.orderData.picurl = orderData.picurl || '',
      this.orderData.valinspay = orderData.valinspay || '',
      this.orderData.chooseBjIndex =  orderData.chooseBjIndex || 0
      if(this.orderData.cargo) {
        // if(this.coname) {
        //   this.availableCom4Brand()
        // }
        // else {
        //   this.availableCom4Brand('popup')
        // }
        this.availableCom4Brand()
      }
    },
    autoChoose () { // 下单优化 自动选择
      let chooseComIndex = this.chooseComIndex
      let availableCom4BrandList = this.availableCom4BrandList
      let chooseIndex = []
      for (let i = 0; i < availableCom4BrandList.length; i++) {
        if (availableCom4BrandList[i].useable === 'Y') {
          chooseIndex.push(i)
        }
        if (availableCom4BrandList[i].kdbest === 'Y' && availableCom4BrandList[i].useable === 'Y') {
          if (chooseComIndex.indexOf(i) === -1) {
            chooseComIndex.push(i)
            this.chooseComIndex = chooseComIndex
            this.chooseComId = this.availableCom4BrandList[i].comService
            this.showBj()
            break
          }
        } else if (i == availableCom4BrandList.length-1 && this.chooseComIndexLength == 0) {
          // 循环到最后都没优选 那就选第一个（除去不能用）
          if (chooseIndex.length > 0) {
            chooseComIndex.push(chooseIndex[0])
            this.chooseComIndex = chooseComIndex
            this.chooseComId = this.availableCom4BrandList[chooseIndex[0]].comService
          }
        }
      }
      this.autoOpen()
    },
    availableCom4BrandOrigin () { // 下单优化 快递公司列表 kdkd
      this.isShowLoading = true // loading
      this.isExpressing = true
      let url = '/apicenter/order.do?method=availableCom4Brand'
      // let latitude = this.chooseNow == 0 ? this.locationInfo.lat : this.resetLat ? this.resetLat : this.sendman.latitude
      // let longitude = this.chooseNow == 0 ? this.locationInfo.lng : this.resetLat ? this.resetLng : this.sendman.longitude
      const params = {
        data: {
          recxzq: this.recman.xzqName.split(',').join(),
          recAddr: this.recman.address || '',
          raddrid: this.recman.id || '',
          saddrid: this.sendman.id || '',
          weight: this.orderData.weight || 1,
          cargo: this.orderData.cargo || '日用品',
          sendxzq: this.sendman.xzqName.split(',').join(),
          sendAddr: this.sendman.address || '',
          cargodesc: this.orderData.cargoDesc.toString(),
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          inviteSign: this.inviteSign || '',
          orderSource: this.orderSource || '',
          dispatchType: 'new'
        }
      }
      const tabBarIndexMap = {
        0: 2,
        1: 1
      }
      params.data.compOrderType = tabBarIndexMap[this.currentButtonIndex]
      this.$http.post(url, params).then((res)=> {
        this.isShowLoading = false
        this.chooseComIndex = []
        this.availableCom4BrandList = res.data
        if(this.currentButtonIndex === 1) {
          this.sortComList(this.availableCom4BrandList)
        }
        // this.showComList = this.availableCom4BrandList.slice(0, this.showComCount)
        this.setShowComList(this.availableCom4BrandList)
        this.isExpressing = false
        this.autoChoose()
        // this.kdbestcoupon()
        // this.queryFirstKuaidCom()
      }).catch(()=>{
        this.isShowLoading = false
        this.chooseComIndex = []
        this.availableCom4BrandList = []
        this.isExpressing = false
      })
    },
    availableCom4Brand (tag) { // 下单优化 快递公司列表 kdkd
      if (tag === 'set') {
        this.showComTips = false // 提醒下方还有x个快递公司 暂时隐藏用的
      }
      this.isShowLoading = true // loading
      this.isExpressing = true
      let url = '/apicenter/order.do?method=availableCom4Brand'
      if (tag === 'popup') {
        this.isShowNewCom = true
        this.checklocaoff()
      }
      // let latitude = this.chooseNow == 0 ? this.locationInfo.lat : this.resetLat ? this.resetLat : this.sendman.latitude
      // let longitude = this.chooseNow == 0 ? this.locationInfo.lng : this.resetLat ? this.resetLng : this.sendman.longitude
      const params = {
        data: {
          recxzq: this.recman.xzqName.split(',').join(),
          recAddr: this.recman.address || '',
          raddrid: this.recman.id || '',
          saddrid: this.sendman.id || '',
          weight: this.orderData.weight,
          cargo: this.orderData.cargo,
          sendxzq: this.sendman.xzqName.split(',').join(),
          sendAddr: this.sendman.address,
          cargodesc: this.orderData.cargoDesc.toString(),
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          inviteSign: this.inviteSign || '',
          orderSource: this.orderSource || '',
          dispatchType: 'new'
        }
      }
      // if(this.coname) {
      //   const tabBarIndexMap = {
      //     0: 2,
      //     1: 1
      //   }
      //   params.data.compOrderType = tabBarIndexMap[this.currentButtonIndex]
      // }
      const tabBarIndexMap = {
        0: 2,
        1: 1
      }
      params.data.compOrderType = tabBarIndexMap[this.currentButtonIndex]
      this.$http.post(url, params).then((res)=> {
        this.isShowLoading = false
        this.chooseComIndex = []
        this.availableCom4BrandList = res.data
        if(this.currentButtonIndex === 1) {
          this.sortComList(this.availableCom4BrandList)
        }
        // this.showComList = this.availableCom4BrandList.slice(0, this.showComCount)
        this.setShowComList(this.availableCom4BrandList)
        this.isExpressing = false
        if (tag === 'set') {
          this.showComTips = true
        }
        this.autoChoose()
        this.kdbestcoupon()
        this.queryFirstKuaidCom()
      }).catch(()=>{
        this.isShowLoading = false
        this.chooseComIndex = []
        this.availableCom4BrandList = []
        this.isExpressing = false
      })
    },
    availableCom4BrandNew() { // 下单优化 快递公司列表
      let oldList = this.availableCom4BrandList
      this.isShowSmallLoading = true
      let url = '/apicenter/order.do?method=availableCom4Brand'
      // let latitude = this.resetLat ? this.resetLat : this.chooseNow == 1 ? (this.sendman.latitude || '') : this.locationInfo.lat
      // let longitude = this.resetLng ? this.resetLng : this.chooseNow == 1 ? (this.sendman.longitude || '') : this.locationInfo.lng
      const params = {
        data: {
          recxzq: this.recman.xzqName.split(',').join(),
          recAddr: this.recman.address || '',
          raddrid: this.recman.id || '',
          saddrid: this.sendman.id || '',
          weight: this.orderData.weight,
          cargo: this.orderData.cargo,
          sendxzq: this.sendman.xzqName.split(',').join(),
          sendAddr: this.sendman.address,
          cargodesc: this.orderData.cargoDesc.toString(),
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          inviteSign: this.inviteSign || '',
          assignSign: this.as || '',
          orderSource: this.orderSource || '',
          dispatchType: 'new'
        }
      }
      // if(this.coname) {
      //   const tabBarIndexMap = {
      //     0: 2,
      //     1: 1
      //   }
      //   params.data.compOrderType = tabBarIndexMap[this.currentButtonIndex]
      // }
      const tabBarIndexMap = {
        0: 2,
        1: 1
      }
      params.data.compOrderType = tabBarIndexMap[this.currentButtonIndex]
      this.$http.post(url, params).then((res) => {
        this.isShowSmallLoading = false
        this.availableCom4BrandList = res.data
        if(this.currentButtonIndex === 1) {
          this.sortComList(this.availableCom4BrandList)
        }
        // this.showComList = this.availableCom4BrandList.slice(0, this.showComCount)
        if (this.chooseComId) {
          const index = this.availableCom4BrandList.findIndex((item) => item.comService === this.chooseComId)
          this.chooseComIndex = [index]
        }
        this.setShowComList(this.availableCom4BrandList)
        for (let i = 0; i < oldList.length; i++) {
          if (oldList[i].serviceList) { // 以前有serviceList
            this.expressBrandServiceList(i, oldList[i].sign, ()=> {
              this.$set(this.availableCom4BrandList[i], 'openList', oldList[i].openList)
              this.$set(this.availableCom4BrandList[i], 'serviceListIndex', oldList[i].serviceListIndex)
            })
          }
        }
        this.getPrice()
      }).catch(() => {
        this.isShowSmallLoading = false
      })
    },
    sortComList(list) {
      list.sort((a, b) => {
        const valueA = this.prescription[a.kuaidiCom] !== undefined ? this.prescription[a.kuaidiCom].predictArriveDay : Number.POSITIVE_INFINITY;
        const valueB = this.prescription[b.kuaidiCom] !== undefined ? this.prescription[b.kuaidiCom].predictArriveDay : Number.POSITIVE_INFINITY;
        return valueA - valueB;
      })
    },
    availableCom4BrandOff () { // 下单优化 快递公司列表 kdkd
      // this.isExpressing = true
      let loading = this.$loading('正在加载')
      let url = '/apicenter/order.do?method=availableCom4Brand'
      // let latitude = this.chooseNow == 0 ? this.locationInfo.lat : this.resetLat ? this.resetLat : this.sendman.latitude
      // let longitude = this.chooseNow == 0 ? this.locationInfo.lng : this.resetLat ? this.resetLng : this.sendman.longitude
      const params = {
        data: {
          recxzq: this.recman.xzqName.split(',').join(),
          recAddr: this.recman.address || '',
          raddrid: this.recman.id || '',
          saddrid: this.sendman.id || '',
          weight: this.orderData.weight,
          cargo: this.orderData.cargo,
          sendxzq: this.sendman.xzqName.split(',').join(),
          sendAddr: this.sendman.address,
          cargodesc: this.orderData.cargoDesc.toString(),
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          inviteSign: this.inviteSign || '',
          orderSource: this.orderSource || '',
          queryOffline: 'Y'
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.offlineComList = res.data.filter(i => i.useable === 'Y') || []
        if (this.offlineComList.length) {
          this.showALipayFailTips = true
        }
        loading.hide()
      }).catch(()=>{
        // this.chooseComIndex = []
        // this.availableCom4BrandList = []
        // this.isExpressing = false
        loading.hide()
      })
      loading.hide()
    },
    /*价格*/
    getPrice () {
      let loading =  this.$loading('正在加载')
      let sendman = this.sendman
      let recman = this.recman
      let url = '/apicenter/order.do?method=pfprice'
      const params = {
        data: {
          sentxzq: sendman.xzqName.split(',').join(),
          sentAddr: sendman.address,
          recxzq: recman.xzqName.split(',').join(),
          recAddr: recman.address,
          weight: this.orderData.weight || 1,
          cargo: this.orderData.cargo || '',
          doortime: this.orderData.doortime || '',
          apiversion: 18,
          comlist: this.myComlist(),
          couponid: this.couponId || 0,
          valinspay: this.orderData.valinspay || '',
          useCoupon: this.useCoupon,
          dispatchType: 'new'
        }
      }
      this.$http.post(url, params).then((r) => {
        loading.hide()
        this.priceInfo = r.data
        if(!params.couponid && this.priceInfo.couponId) {
          this.couponId = this.priceInfo.couponId
          console.log(this.couponId);
        }
        // priceInfo.preprice = Number(priceInfo.totalprice * 10 - priceInfo.couponPrice * 10) / 10
        if (this.priceInfo.nightFee) {
          this.priceInfo.nightFee = Number(this.priceInfo.nightFee)
        }
        // let price = priceInfo.preprice
        // priceInfo.idx = 0
        //微信支付分
        // this.priceInfo = priceInfo
      }).catch(()=> {
        this.priceInfo = null
        loading.hide()
      })
    },
    showBj () { // 是否显示保价
      let chooseComIndex = this.chooseComIndex
      let availableCom4BrandList = this.availableCom4BrandList
      if (chooseComIndex.length == 0 || availableCom4BrandList.length == 0) {
        this.isShowBj = true
      }
      for (let i = 0; i < chooseComIndex.length; i++) {
        if (availableCom4BrandList[chooseComIndex[i]].kdbest === 'Y') {
          this.isShowBj = true
        } else if (i == chooseComIndex.length - 1) {
          this.isShowBj = false
        }
      }
    },
    checkModify (data) {
      let origin = this.contactMetas.type === 'send' ? this.sendman : this.recman
      let keys = ['province', 'district', 'city', 'addr']
      for(let i = 0; i < keys.length; i++) {
        if (origin[keys[i]] + "" != data[keys[i]]) {
          return true
        }
      }
      return false
    }
  },
  beforeDestroy () {
    
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
    })
  },
  watch: {
    'orderData.weight': function(val) {
      if (Number(val) < 1 || Number(val) > 20 || isNaN(val)) {
        if (val) {
          this.$toast('请输入1-20之间的整数')
          this.$nextTick(()=> {
            this.orderData.weight = Number(val.substring(0, val.length - 1))
          })
        }
      } else {
        this.$nextTick(()=> {
          this.orderData.weight = Number(val)
        })
      }
    },
    'orderData.valinspay': function(val) {
      let valinspay = val, valinsFee
      valinsFee = Math.max(Math.round((+this.orderData.valinsrate || 0.005) * valinspay), 2)
      if (valinspay == '') valinsFee = ''
      this.orderData.valinsFee = valinsFee
      this.orderData.valinspay = valinspay
    },
    couponModal(val) {
      if(!val) {
        const tag = this.oldId === this.couponId ? 'Y' : 'N'
        // this.$emit('couponModalClose', tag)
        this.couponModalClose(tag)
      } else {
        this.oldId = this.couponId
      }
    }
  }
}