<template>
  <Mpopup
      v-model="showTakeLoc"
      title="选择取件位置"
      :mask-clickable="true"
    >
    <div class="gotaddr-main">
      <div class="gotaddr-tips">快递员会到您选择的取件位置上门取件，不同的取件位置可能周围的快递服务不同。</div>
      <div class="gotaddr-box">
        <div class="gotaddr-label">当前定位：</div>
        <div class="gotaddr"
          :class="[chooseNow == 0 ? 'chooseNow' : '']"
          @click="chooseNowLoc(0)">
          <img class="gotaddr-choose"
            v-if="chooseNow == 0"
            src="https://cdn.kuaidi100.com/images/better/choose.png">
          <div class="gotaddr-info">
            <img src="https://cdn.kuaidi100.com/images/better/loc.png">
            <div class="gotaddr-item">
              <div class="gotaddr-xzq" v-if="gotXzq.pro">{{gotXzq.pro}} {{gotXzq.city||''}}
                {{gotXzq.district||''}}
              </div>
              <div class="gotaddr-detail">{{gotAddr}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gotaddr-box">
        <div class="gotaddr-label">寄件人地址：</div>
        <div class="gotaddr" 
          :class="[chooseNow == 1 ? 'chooseNow' : '']" @click="chooseNowLoc(1)">
          <img class="gotaddr-choose"
            v-if="chooseNow == 1"
            src="https://cdn.kuaidi100.com/images/better/choose.png">
          <div class="gotaddr-info">
            <img src="https://cdn.kuaidi100.com/images/m/dispatch/ico_send.png" style="width: 1.5rem;">
            <div class="gotaddr-item">
              <div class="gotaddr-xzq">{{sendman.xzqName}}</div>
              <div class="gotaddr-detail">{{sendman.address}}</div>
            </div>
          </div>
          <div class="sendaddr-tips" v-if="!isResetLoc && !address2geoData" @click.stop="goMap">
            <img src="https://cdn.kuaidi100.com/images/rebuild/icon_tips.png">
            <div>该地址无法获取准确的经纬度，若使用该地址取件，请点击在地图上确认位置。</div>
          </div>
          <div class="sendaddr-tips" v-if="isResetLoc && !address2geoData" @click.stop="goMap">
            <div>重新选择该地址在地图上的位置</div>
          </div>
        </div>
      </div>
    </div>
  </Mpopup>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
export default {
  name: 'takeLoc',
  components: {
    Mpopup
  },
  data() {
    return {
      showTakeLoc: false
    }
  },
  props: {
    value: Boolean,
    chooseNow: {
      type: Number,
      default: 0
    },
    gotXzq: {
      type: Object,
      default: {}
    },
    gotAddr: {
      type: String,
      default: ''
    },
    sendman: {
      type: Object,
      default: {}
    },
    isResetLoc: {
      type: Boolean,
      default: false
    },
    address2geoData: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (val) {
      this.showTakeLoc = val
    },
    showTakeLoc (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    
  },
  methods: {
    chooseNowLoc (index) {
      this.$emit('chooseNowLoc', index)
    },
    goMap () {
      this.$emit('goMap')
    }
  }
}
</script>

<style lang="scss" scoped>
.gotaddr {
  padding: 0.9375rem 0;
  box-shadow: 0 0 0.1875rem #ddd;
  border-radius: 0.25rem;
  margin-top: 0.5625rem;
  width: 90%;
  margin-left: 0.1875rem;
  position: relative;
  border: 0.125rem solid rgba(0, 0, 0, 0.1);
  width: unset;
  box-shadow:0px 0px 0.5rem 0px rgba(0,0,0,0.2);
  &-main {
    padding: 0 1.25rem 1.25rem 1.25rem;
    height: 24.625rem;
  }
  &-tips {
    font-size: 0.8125rem;
    color: #888;
    span {
      color: #ff7f02;
    }
  }
  &-box {
    margin-top: 1.5625rem;
    .gotaddr-label {
      font-size: 0.8125rem;
      color: #333;
    }
  }
  &:last-child {
    margin-bottom: 0.1875rem;
  }
  &-info {
    display: flex;
    align-items: flex-start;
    .gotaddr-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
      width: 74%;
      .gotaddr-xzq {
        font-size: 1.0625rem;
        color: #333;
      }
      .gotaddr-detail {
        font-size: 0.875rem;
        color: #333;
        margin-top: 0.3125rem;
      }
    }
    img {
      width: 1.125rem;
      height: 1.375rem;
      margin-left: 0.625rem;
      margin-top: 0.1875rem;
    }
  }
  &-choose{
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    top: 50%;
    margin-top: -0.625rem;
    right: 0.625rem;
  }
  &.chooseNow {
    border: 0.125rem solid rgba(49, 126, 231, 0.3);
    box-shadow:0px 0px 0.625rem 0px rgba(49,126,231,0.2);
  }
  .sendaddr-tips {
    display: flex;
    font-size: 0.625rem;
    color: #ff7f02;
    margin-left: 0.8125rem;
    margin-top: 0.8125rem;
    padding-right: 0.3125rem;
    img {
      width: 0.6875rem;
      height: 0.6875rem;
      margin-right: 0.3125rem;
      margin-top: 0.125rem;
    }
  }
}
</style>