<template>
  <div class="newsearch">
    <Modal v-model="dialogShow" cancelText="重新选择" confirmText="确定" title="提示" @confirm="handleTip" :zIndex="2000">
      是否用<span style="color: #ff7f02;">「{{locName}}」</span>的位置作为寄件地址
      <span style="color: #ff7f02;">「{{resetAddr}}」</span>的准确位置？
    </Modal>
    <div class="head">
      <div class="search focus">
        <div class="placeholder" v-if="!keyword">
          <img class="ico" src="https://cdn.kuaidi100.com/images/rebuild/city_search_search.png" mode="widthFix" />请输入小区、建筑、街道名称
        </div>
        <input type="text" @input="search" v-model="keyword" @focus="toggleFocus" @blur="toggleFocus" :focus="true"/>
      </div>
    </div>
    <!--提示-->
    <div class='search-words' v-if="isEmpty !== 0">
      <img src="https://cdn.kuaidi100.com/images/rebuild/image/ico_search1.png" />
      <span>建议只搜索小区、建筑、街道名称</span>
      <span>如：金蝶大厦、幸福小区、科技路2号</span>
    </div>
    <div class='search-words' v-if="isEmpty === 0">
      <img src="https://cdn.kuaidi100.com/images/rebuild/image/no_search.png" />
      <span>搜索不到结果</span>
      <span>请搜索离目标最近的标志性建筑</span>
      <span>建议删除掉地址中的栋、楼、门牌号等信息</span>
    </div>
    <!--加载-->
    <div v-if="showLoading" class='samec-showLoading'>
      <img src="http://cdn.kuaidi100.com/images/smart/order/loading.gif"/>
      <span>正在加载...</span>
    </div>
    <!--输入keywords的列表-->
    <div class='samec-search' v-if="keyword && searchData.length > 0">
      <div class='samec-main'
        v-for="(item, index) in searchData"
        :key="index"
        @click.stop="chooseLoc(item)">
        <div class='samec-main-name'>
          <span class='samec-main-name-detail'>{{item.name}}</span>
        </div>
        <div class='samec-main-loc'>{{item.adname}} {{item.address}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Bus from './bus.js'; 
import Modal from "components/Modal/Modal"
export default {
    components: {
			Modal
    },
    computed: {
      ...mapState({
        locationInfo: state => state.position
      })
    },
    mounted () {
      console.log(this.$route)
      this.resetAddr = this.$route.query.resetAddr
      this.keyword = this.$route.query.resetAddr
      this.city = this.$route.query.resetXzq.split(',')[1]
      this.searchKeyWord()
    },
		data() {
			return {
        resetAddr: '',
        isEmpty: 1,
        keyword: '',
        tag: "all",
        allData: {},
        timer: null,
        searchData: [],
        showWait: '',
        showLoading: false,
        keywordFocus: false,
        dialogShow: false,
        item: {},
        locName: ''
			}
		},
    methods: {
      ...mapActions('location', [
        'getLocation'
      ]),
      chooseLoc(item) { // 点击搜索选择的地址
        this.locName = item.name
        this.dialogShow = true
        console.log(this.dialogShow)
        this.item = item
      },
      handleTip () {
        console.log('?????')
        const res = {
          location: this.item.location,
          isResetLoc: true,
          resetLng: this.item.location.split(',')[0],
          resetLat: this.item.location.split(',')[1],
          chooseNow: 1
        }
        Bus.$emit('addMix', res)
        this.$router.go(-1)
      },
      toggleFocus() {
        this.keywordFocus = !this.keywordFocus
      },
      search(e) { // 输入
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          // this.keyword = e.detail.value
          this.searchKeyWord()
        }, 500)
      },
      searchKeyWord() { // 搜索关键词
        if(!this.keyword) return
        // let url = 'https://restapi.amap.com/v3/place/text'
        const url = '/third/api/gaodeform'
        const params = {
          data: {
            // key: '77d5e24ecae26447c607d3a152b3ddf5',
            keywords: this.keyword,
            types: '',
            city: this.city,
            children: 1,
            offset: 10,
            page: 1,
            extensions: 'all',
            path: 'v3/place/text',
            pd: 'SELF',
            client: 'M',
            ref: '/express/dispatch/search',
            jscode: '6be829187e17a2d6466b65225ea4c131',
            s: 'rsv3'
          },
          emptyUser: true
        }
        this.$http.post(url, params).then((res)=> {
          if (res.data.infocode === '10000') {
            this.searchData = res.data.pois
            this.isEmpty = res.data.pois.length
            } else {
              this.$toast('网络错误，请稍后再试')
            }
        })
      },
      clearInput() { // 清除input框
        this.keyword = ''
      }
    }
	}
</script>

<style lang="scss" scoped>
  /* pages/global/contact/list.wxss */
  .newsearch {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
  }
  .head {
    background: #fff;
  }
  .search {
    position: relative;
    display: flex;
    align-items: center;
    width: 19.6875rem;
    height: 2.1875rem;
    margin: 0.625rem auto;
    padding: 0 0.9375rem;
    border-radius: 2.1875rem;
    background:rgb(239,239,244);
    font-size: 0.875rem;
  }
  .search input {
    width: 100%;
    height: 100%;
    padding-left: 0.625rem;
    box-sizing: border-box;
  }
  .search .placeholder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #bebebe;
  }
  .search.focus .placeholder {
    left: 0.625rem;
    top: 50%;
    transform: translate(0,-50%);
  }
  .search.focus {
    justify-content: flex-start;
  }
  .search.focus input {
    width: 0;
    flex: 1;
  }
  .search .ico {
    width: 0.75rem;
    margin-right: 0.3125rem;
    vertical-align: -0.125rem;
  }
  .search .close {
    width: 1.125rem;
  }
  .tab {
    display: flex;
    justify-content: space-around;
  }
  .tab span {
    padding: 0.3125rem 0.3125rem 0.625rem;
    box-sizing: border-box;
    color: #888;
  }
  .tab text.active {
    color: #327ee7;
    border-bottom: 0.125rem solid;
  }
  .common-footer {
    background: transparent;
  }
  .container .list {
    position: relative;
    flex: 1;
    height: 0;
  }
  .list scroll-view {
    position: absolute;
    top: 0.625rem;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .list .item-left {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0.625rem; 
    box-sizing: border-box;
    background: #FFF;
  }
  .common-radio {
    margin-left: 0;
    margin-right: 0.9375rem;
  }
  .common-radio::before {
    width: 0.625rem;
    height: 0.625rem;
    border: 0.3125rem solid #fff;
  }
  .item-left .info {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
  }
  .info .name {
    font-size: 1.0625rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info .addr {
    font-size: 0.875rem;
    color: #888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } 
  .item-left .edit {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGNkY2NTJFMjM2RTUxMUU3QkNFRDk2NkNDOUQ5RTA0QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBQTVDODEzODM2RUQxMUU3QkNFRDk2NkNDOUQ5RTA0QSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkY2RjY1MkUwMzZFNTExRTdCQ0VEOTY2Q0M5RDlFMDRBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkY2RjY1MkUxMzZFNTExRTdCQ0VEOTY2Q0M5RDlFMDRBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+MzstWwAAAUBJREFUeNpiNG18zUAsYGP+y0AFUA+lG0EECxB7AvFcIJYkpPPXX2ZiHcqIx/IGJH4jC7GWU8nnyJY3wEIAZjkjDaMA3XIY4GQaAJ/DwHQgrmQaIMuXAnEOEP9nGgDLNwFxAhD/A3GY6Gz5PiAOA+I/MAEmOlp+Eoj9gPgnsiATnSy/DMQeQPwVXYKJDpbfAWIXIP6ATRMTjS1/AsROQPwKWpL+B2FqOwCX5a+hlj/Gp5mJRpZ/hAb7bUIGMNHA8m/QBHeJGEOYqGz5L2hWO0GsQeQ4QAGH5X+hhcxeUgwjxwF2WMT+Q4vXjaQaRo4DHLGIgSqWJeTEJTkOcEDjVwHxNHJTMgsZehSpWXaT5ABi24TEmHOqToRmtSHN08CoA0YdMOoAqjvgOVKFQmsMAi/QHZCCLkhD8BSIk5EFAAIMAFFeYc7pTNvvAAAAAElFTkSuQmCC') center center no-repeat;
    background-size: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }  
  .item-left .wrap {
    display: flex;
    align-items: center;
    width: 0;
    height: 4.375rem;
    flex: 1;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
  }
  .list .item-right {
    display: flex;
  } 
  .item-right .button {
    height: 100%;
    padding: 0 1.25rem;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 0.9375rem;
    line-height: 4.375rem;
    border: none;
    color: #FFF;
  }
  .item-right .button:first-child {
    background: #ff9600;
  }
  .item-right .button:last-child {
    background: #ff3824; 
  }

  .cover-view {
    position:absolute;
    text-align:center;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    left:50%;
    transform:translateX(-50%);
    top:50%;
    margin-top:-1.875rem;
  }

  .cover-image {
    height: 1.875rem;
    width:1.375rem;
  }

  .cover-name {
    padding:0.125rem;
    border:1px solid #ddd;
    box-shadow:0px 2px 0.125rem #ddd;
    width:100%;
    background-color:#fff;
    border-radius:0.125rem;
    font-size: 0.875rem;
  }

  .flex-wrp{
    display:flex;
  }

  .flex-item{
    width: 6.25rem;
    height: 9.375rem;
    font-size: 0.8125rem;
  }

  .demo-text-1 {
    background: rgba(26, 173, 25, 0.7);
  }

  .demo-text-2 {
    background: rgba(39, 130, 215, 0.7);
  }

  .demo-text-3 {
    background: rgba(255, 255, 255, 0.7);
  }

  .samec {
    width: 100%;
    background: #fff;
    flex:1;
    overflow:auto;
  }

  .samec-title {
    font-size: 0.875rem;
    color: #888;
    padding: 14px 0 0 16px;
  }

  .samec-scroll {
    
  }

  .samec-main {
    padding:12px 0 12px 16px;
    border-bottom:1px solid #eee;
  }

  .samec-main-name {
    display:flex;
    align-items:center;
  }

  .samec-main-name-detail {
    font-size: 1.0625rem;
    color: #333;
  }

  .samec-main-name-now {
    background-color: rgba(255, 127, 2, 0.1);
    color: #ff7f02;
    border-radius: 0.125rem;
    padding: 0.125rem 0.25rem;
    font-size: 0.6875rem;
    margin-left: 0.25rem;
  }

  .samec-main-loc {
    color: #888;
    font-size: 0.875rem;
  }

  .samec-search {
    width:100%;
    background:#fff;
    position:absolute;
    top:3.125rem;
    overflow:auto;
    flex:1;
    height:calc(100% - 3.125rem);
  }

  .showDiff {
    width: 100%;
    display:flex;
    flex-wrap:wrap;
    background:#fff;
    padding-bottom:180px;
    height:calc(100% - 18.1875rem);
    justify-content:center;
    font-size:0.875rem;
    color:#888;
    align-content:center;
  }

  .showDiff span {
    width: 100%;
    text-align: center;
  }

  .samec-showLoading {
    width: 100%;
    height: calc(100% - 18.1875rem);
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content:center;
  }

  .samec-showLoading image {
    width: 7.125rem;
    height: 2.5625rem;
    margin-top: -40px;
  }

  .samec-showLoading span {
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: #888;
  }

  .search-words {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6.5rem;
  }

  .search-words image{
    width: 3.5rem;
    height: 3.5rem;
  }

  .search-words text:nth-child(2) {
    margin-top: 0.875rem;
  }

  .search-words span {
    color: #888;
    font-size: 0.875rem;
    width: 100%;
    text-align: center;
  }
</style>