<template>
  <div class="protocol-wrap">
    <div class="webview-wrap">
      <webview src="https://m.kuaidi100.com/app/frame/valinsProtocol.jsp"></webview>
    </div>
    <div class="global-btn" @click="agree">同意</div>
  </div>
</template>
<script>
export default {
  name: "valins-protocol",
  methods: {
    agree() {
      this.$broadcastChannel.$emit("do-valins-agree")
      this.$router.go(-1)
    }
  },
  // beforeRouteEnter (to, from, next) {
  //   if(!to.params.source) {
  //     next(vm => {
  //       vm.navigator.replace({
  //         name: "courier"
  //       })
  //     })
  //   } else {
  //     next()
  //   }
  // }
}
</script>
<style scoped>
.protocol-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFF;
}
.webview-wrap {
  flex: 1;
}
</style>