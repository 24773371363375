<template>
  <div class="dbp">
    <div class="showitem" v-if="isShowDBP" @click="goDBP">
      <div class="showitem-left-sign">限时体验</div>
      <div class="showitem-left">
        <img class="showitem-left-img1" src="https://cdn.kuaidi100.com/images/better/dbp.png"><div class="showitem-right">本单快递100为您提供</div><img class="showitem-left-img2" src="https://cdn.kuaidi100.com/images/better/question.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dbp',
  data() {
    return {
      
    }
  },
  props: {
    isShowDBP: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    
  },
  mounted () {
    this.getDictNameByVal()
  },
  methods: {
    getDictNameByVal () { // 丢必赔
      let url = '/apicenter/xcx.do?method=getDictNameByVal&code=COMMON_CONFIG&val=insure_switch'
      this.$http.get(url).then((res)=> {
        this.$emit('update:isShowDBP', res.data === 'Y')
        // this.isShowDBP = res.data === 'Y'
      })
    },
    goDBP () {
      this.$router.push({
        name: "dispatchDbp"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dbp {
  margin: 0 10px;
  .showitem {
    height: 2.6875rem;
    background: #fff;
    border-radius: 0.25rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    margin: 0.625rem auto 0;
    position: relative;
    &-left {
      display: flex;
      align-items: center;
      &-sign {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to right, #FF7F02,#F64F4F);
        color: #fff;
        font-size: 0.5rem;
        border-top-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        padding: 0 0.25rem;
        transform: scale(0.7);
        margin-left: -0.45rem;
        margin-top: -0.1rem;
      }
      &-img1 {
        width: 8rem;
        height: 1.1875rem;
      }
      &-img2 {
        position: absolute;
        right: 1rem;
        width: 1.0625rem;
        height: 1.0625rem;
      }
    }
    &-right {
      font-size: 0.75rem;
      padding-left: 0.375rem;
      color: #bebebe;
      width: 9.375rem;
    }
  }
}
</style>