<template>
  <div class="com-list">
    <div class="com-list-header">
      <div class="left">
        {{title}}
      </div>
      <div class="right">
        <div v-for="(item, index) in buttonList" :key="index"
         @click="switchTabIndex(index)"
         class="right-button"
         :class="{active: currentTabIndex === index}">
         <img :src="currentTabIndex === index ? item.imgChoosePath : item.imgPath" alt="">
         {{item.tabname}}
         </div>
      </div>
    </div>
    <div class="com-list-content" v-if="showComList.length > 0">
      <div v-for="(item) in showComList" :key="item.comService" class="com-list-content-item" :class="{active: comIndex === item.comService}" @click="choose(item)">
        <div class="com-list-content-item-left">
          <template v-if="currentTabIndex === 1 && prescription[item.kuaidiCom] && prescription[item.kuaidiCom].predictArriveDay">
            <span class="num">{{ prescription[item.kuaidiCom] ? prescription[item.kuaidiCom].predictArriveDay : ''}}</span>天后到
          </template>
          <template v-else-if="currentTabIndex === 0 && costTotalPrice(item) >=0 ">
            ¥<span class="num" :class="{ largeNum: largeNum(costTotalPrice(item)) > 4}">{{costTotalPrice(item)}}</span>起
            <!-- ¥<div class="num">{{costTotalPrice(item)}}</div>起 -->
          </template>
        </div>
        <div class="com-list-content-item-com">
          <div class="com-list-content-item-com-name">
            <img :src="item.logo" alt="">
            <span>{{item.name}}</span></div>
            <template v-if="currentTabIndex === 1">
              <div class="com-list-content-item-com-price">¥{{costTotalPrice(item)}}起 | {{item.label}}</div>
            </template>
            <template v-else-if="currentTabIndex === 0 && prescription[item.kuaidiCom] && prescription[item.kuaidiCom].predictArriveDay">
              <div class="com-list-content-item-com-price">{{ prescription[item.kuaidiCom] ? prescription[item.kuaidiCom].predictArriveDay : ''}}天后到 | {{item.label}}</div>
            </template>
        </div>
        <div class="com-list-content-item-check">
          <img :src="comIndex === item.comService ? 
          'https://cdn.kuaidi100.com/images/wxapp/icons/ico_checkbox_checked.png' : 'https://cdn.kuaidi100.com/images/m/dispatch/no-choose.png' " alt="">
        </div>
      </div>
      <div v-if="isShowMore" @click="showMore" class="com-list-content-more">更多快递</div>
    </div>
    <div v-else class="no-data">
      <img src="https://cdn.kuaidi100.com/images/uniapp/result/bg-no-courier.png" alt="">
      <div class="text">暂无运力，请重填一个地址试试</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonList: [
        {tabname: "价格优先", imgPath: 'https://cdn.kuaidi100.com/images/m/dispatch/price.png', imgChoosePath: 'https://cdn.kuaidi100.com/images/m/dispatch/price-blue.png'},
        {tabname:"时效优先", imgPath: 'https://cdn.kuaidi100.com/images/m/dispatch/effectiveness-black.png', imgChoosePath: 'https://cdn.kuaidi100.com/images/m/dispatch/effectiveness.png'}
      ],
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    currentTabIndex: {
      type: Number,
      default: 0
    },
    comIndex: {
      type: String,
      default: ''
    },
    // buttonList: {
    //   type: Array,
    //   default() {
    //     return []
    //   }
    // },
    showComList: {
      type: Array,
      default() {
        return []
      }
    },
    isShowMore: {
      type: Boolean,
      default: false
    },
    prescription: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    switchTabIndex(index) {
      this.$emit('switchTabIndex', index)
    },
    showMore() {
      this.$emit('showMore')
    },
    costTotalPrice (item) {
      if (item.serviceListIndex === 0 || item.serviceListIndex == 1) {
        const data = item.serviceList[item.serviceListIndex]
        return data && data.priceText ? data.priceText : item.costTotalPrice || -1
      } else {
        return item.costTotalPrice || -1
      }
    },
    choose(item) {
      this.$emit('choose', item)
    },
    largeNum(num) {
      const numberStr = num.toString()
      return numberStr.includes('.') ? numberStr.replace('.', '').length : numberStr.length
    }
  },
}
</script>
<style lang="scss" scoped>
.com-list {
  box-sizing: border-box;
  width: auto;
  height: 100%;
  border-radius: .5714rem .5714rem 0px 0px;
  background-color: #fff;
  padding: 1.0714rem .7143rem;
  margin: .7143rem;
  margin-bottom: 0;
  border-bottom: 1px solid #efeff4;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.4286rem;
    .left {
      color: #001A32;
      font-size: .8571rem;
      line-height: 1.4286rem;
    }
    .right {
      display: flex;
      gap: .7143rem;
      &-button {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 1.4286rem;
        font-size: .7857rem;
        line-height: 1.4286rem;
        color: #000000;
        background-color: #e0e3e6;
        border-radius: .7143rem;
        padding: 0 .7143rem;
        img {
          width: .8571rem;
          height: .8571rem;
          margin-right: 2px;
        }
      }
      .active {
        background-color: #e5f2fe;
        color: #0082FA;
      }
    }
  }
  &-content {
    width: 100%;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 5.7143rem;
      background-color: #FCFCFC;
      margin-top: .7143rem;
      border-radius: .7143rem;
      border: 1px solid #d1d6da;
      &-left {
        display: flex;
        align-items: center;
        width: 6.4286rem;
        color: #FF7F02;
        font-size: 1rem;
        height: 1.7143rem;
        line-height: 1.7143rem;
        margin-left: 1.4286rem;
        .num {
          display: inline-block;
          font-size: 1.7143rem;
          font-weight: bold;
          margin-right: .2143rem;
          max-width: 6.8571rem
        }
        .largeNum {
          font-size: 1.4286rem;
        }
      }
      &-com {
        display: flex;
        flex-direction: column;
        // margin-left: 2.5rem;
        gap: .7143rem;
        &-name {
          display: flex;
          align-items: center;
          font-size: .9286rem;
          color: #001A32;
          height: 1.5rem;
          span {
            opacity: 0.7;
          }
          img {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            margin-right: .3571rem;
            border: 1px solid #e8e8e8;
          }
        }
        &-price {
          font-size: .8571rem;
          color: #7F8C98;
        }
      }
      &-check {
        position: absolute;
        right: .7143rem;
        top: 1.2857rem;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
        }
      }
    }
    .active {
      background-color: #ECF6FF;
      border-color: #0082FA;
    }
    &-more {
      position: relative;
      color: #0082FA;
      font-size: .8571rem;
      margin: .7143rem auto 0;
      text-align: center;
      width: 3.5714rem;
      &::after {
        content: '';
        position: absolute;
        top: .2857rem;
        right: -0.5714rem;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #0082FA #0082FA transparent;
        width: .2857rem;
        height: .2857rem;
        transform: rotate(-45deg);
      }
    }
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 18.2857rem;
      height: 11.8571rem;
    }
    .text {
      margin: .2857rem 0 1rem;
      font-size: .8571rem;
      color: #001A32;
      opacity: 0.7;
    }
  }
}
</style>